import React, { useState, useEffect} from 'react';
import './App.css';
import donut from "./donut.mp4";
import tshirt from "./boysdontlie.mov";
import tshirtback from "./boysdontlieback.jpeg";
import tshirtfront from "./boysdontliefront.jpeg";
import insta from "./insta.png";
import { useGlitch } from 'react-powerglitch';
import AWS from 'aws-sdk';

AWS.config.update({
  region: 'us-east-2',
  accessKeyId: 'AKIAYGMMQJ5TU7WBYNRK',
  secretAccessKey: '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',
});

const docClient = new AWS.DynamoDB.DocumentClient();

function App() {
  const glitch = useGlitch({ timing: { duration: 11000 }, glitchTimeSpan: { start: 0.8, end: 1.0 } });
  const elements = ['video', 'tshirt']; // Define the elements
  const [currentElementIndex, setCurrentElementIndex] = useState(0);
  const [email, setEmail] = useState('');
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);


  const handleTouchStart = (event) => {
    setTouchStartX(event.touches[0].clientX);
  };
  
  const handleTouchMove = (event) => {
    setTouchEndX(event.touches[0].clientX);
  };
  
  const handleTouchEnd = () => {
    const swipeDistance = touchEndX - touchStartX;
  
    if (swipeDistance > 50) {
      handlePrevElement(); // Right swipe
    } else if (swipeDistance < -50) {
      handleNextElement(); // Left swipe
    }
  };

  
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const params = {
      TableName: 'dvd-emails',
      Item: {
        email: email,
      },
    };

    try {
      docClient.put(params).promise();
      setEmail('');
      console.log('Email added to DynamoDB');
    } catch (error) {
      console.error('Error adding email to DynamoDB:', error);
    }
  };

  function handleArrowKeys(event) { 
    // eslint-disable-line react-hooks/exhaustive-deps
    switch (event.keyCode) {
      case 37: // Left arrow key
        handlePrevElement();
        break;
      case 39: // Right arrow key
        handleNextElement();
        break;
      default:
        break;
    }
  }

  const handleVideoClick = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentElementIndex((prevIndex) => (prevIndex + 1) % elements.length);
    }, 11000);

    // Add the event listener for arrow keys
    window.addEventListener('keydown', handleArrowKeys);

    return () => {
      clearInterval(interval);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePrevElement = () => {
    setCurrentElementIndex((prevIndex) => (prevIndex - 1 + elements.length) % elements.length); // Ensure non-negative index
  };

  const handleNextElement = () => {
    setCurrentElementIndex((prevIndex) => (prevIndex + 1) % elements.length);
  };

  const handleInstaLogoClick = () => {
    window.open('https://instagram.com/504apparel.ca?igshid=MzRlODBiNWFlZA==', '_blank');
  };

  return (
    <div className="app-container">
      <div className="video-container">
        <div className="content-container">
          <div className="titlebox">
            <h1 className="title">
              504 Apparel<span className="trademark">&#174;</span>
            </h1>
            <div className="rotate-504-text">
              <p className="about-504-text">
              Find Me In The Hills Collection Dropping 12/19/2023
              </p>
            </div>
          </div>
          <div className="flex-container" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
            <div>
              <button className="arrow-left-button" onClick={handlePrevElement}>
                ←
              </button>
            </div>
            <div>
              {elements[currentElementIndex] === 'video' ? (
                <div ref={glitch.ref}>
                  <video
                    className="video-donut"
                    src={donut}
                    type="video/mp4"
                    autoplay="autoplay"
                    loop
                    muted
                    playsinline
                    preload
                    onClick={handleVideoClick}
                  ></video>
                </div>
              ) : elements[currentElementIndex] === 'collection' ? (
                <div ref={glitch.ref}>
                  <h1 className="about-text">
                    504xDVD - Find Me In The Hills Collection <br /> Dropping
                    12/19/2023
                  </h1>
                </div>
              ) : (
                <div ref={glitch.ref}>
                  <div className="flex-container-tee">
                    <div className="image-tshirt-box">
                      <img className="image-tshirt-back" src={tshirtback} alt="tshirt-back"></img>
                    </div>
                    <div>
                      <video
                        className="video-tee"
                        src={tshirt}
                        type="video/mov"
                        autoplay="autoplay"
                        loop
                        muted
                        playsinline
                        preload
                      ></video>
                    </div>
                    <div className="image-tshirt-box">
                      <img className="image-tshirt-front" src={tshirtfront}  alt="tshirt-front"></img>
                    </div>
                  </div>
                  <h1 className="about-tee">
                    Girls Don't Lie Boys Don't Cry Tee - $25
                  </h1>
                </div>
              )}
            </div>
            <div>
              <button className="arrow-right-button" onClick={handleNextElement}>
                →
              </button>
            </div>
          </div>
  
          <div className="catch-box">
            <h3 className="catch-text">pre-order with 20% off...</h3>
            <form className="email-form" onSubmit={handleSubmit}>
              <input
                className="email-input"
                type="email"
                placeholder="email"
                value={email}
                onChange={handleEmailChange}
              />
              <button className="email-button" type="submit">
                $ubmit
              </button>
            </form>
            <img className="insta-logo" src={insta} onClick={handleInstaLogoClick} alt="insta"></img>
          </div>
        </div>
      </div>
    </div>
  );
}  

export default App;
